import ChainedBackend from 'i18next-chained-backend';
import FsBackend from 'i18next-fs-backend';
import HttpApi from 'i18next-http-backend';
import {
    type UserConfig,
    type I18n as I18NextClient,
    type SSRConfig,
    useTranslation as i18NextUseTranslation,
} from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { BACKEND_LOAD_PATH, defaultLocale, LOCALE_PATH, locales } from './config';
import { isBrowser, isDev, shouldUseI18Next } from './env';
import { loadLocaleFrom } from './utils';
import { getFallbackNamespaces } from './utils/ns';

const ensureHyphens = (locale: string) => locale.replace('_', '-');

/**
 * @name getConfig
 * @description Get the i18n configuration object for i18next and NextJS (i18n)
 */
export const getConfig = (ns?: string[]): UserConfig => {
    ns ??= getFallbackNamespaces();

    return {
        ns,
        debug: isDev,
        i18n: {
            defaultLocale: ensureHyphens(defaultLocale),
            locales: locales.map(ensureHyphens),
            localeDetection: false,
        },
        localePath: LOCALE_PATH,
        serializeConfig: false,
        backend: {
            loadPath: BACKEND_LOAD_PATH,
            backends: isBrowser ? [HttpApi, FsBackend] : [],
        },
        preload: isBrowser ? [] : locales,
        use: isBrowser ? [ChainedBackend] : [],
    };
};

// @todo: implement the app folder useTranslation hook
// @note: cast as any to avoid type errors for placeholder
const placeholderTranslation = () => ({ t: (key: string) => key });

/**
 * @name useTranslation hook
 * @description Temporary adapter hook for useTranslation function. This should be imported instead of the actual useTranslation hook from next-i18next.
 * @todo implement the app folder useTranslation hook
 * @example
 * import { useTranslation } from '@pxr/i18n';
 *
 * const { t } = useTranslation('common');
 *
 * t('title')
 **/
export const useTranslation = shouldUseI18Next
    ? i18NextUseTranslation
    : // @todo: any cast to avoid type errors for placeholder
      (placeholderTranslation as any);

/**
 * @name getTi18Next
 * @param {*} i18n
 * @param {*} locale
 * @param {*} ns
 * @description Get t function from i18next for a specific locale and namespace to use on the server
 * @example
 * import { i18n } from 'next-i18next';
 * import { getTi18Next } from '@pxr/i18n';
 *
 * const __meta = await getTi18Next(i18n, 'en', 'common').t;
 *
 * console.log(__meta`some-key`));
 */
export const getTi18Next = async (i18n: I18NextClient | null, locale: string, ns: string) => {
    if (!i18n) {
        // @todo: replace import from next-i18next with a custom i18n instance when app folder is implemented
        throw new Error(
            'i18n singleton instance from next-i18next is required to set the resources to the proper context. Please use `import { i18n } from "next-i18next"` and pass it to the getTi18Next function.',
        );
    }

    if (!locale || !ns) {
        throw new Error('Locale and namespace are required to get the i18next t function.');
    }

    // i18next via next-i18next loads locales at build time.
    // getFixedT needs to access locale files at run time, so load them manually.
    // files are cached in loadLocaleFrom fn, but can be replaced by i18next-backend
    // packages that support fs/localStorage/cdn/etc.
    const localeFile = await loadLocaleFrom(locale, ns);
    // and add them to the i18n instance using the correct locale and namespace
    i18n?.addResourceBundle(locale, ns, localeFile);
    // get the fixed t function
    const t = i18n?.getFixedT(locale, ns);

    return t;
};

export const getServerTranslations = async (
    locale: string,
    namespacesRequired?: string | string[] | undefined,
    configOverride?: UserConfig,
    extraLocales?: string[] | false,
): Promise<SSRConfig> => {
    const config = configOverride ?? getConfig();
    return serverSideTranslations(locale, namespacesRequired, config, extraLocales);
};
